import Footer from "../components/core/Footer";
import Navigbar from "../components/core/Navigbar";
import ScrollButton from "../components/core/ScrollButton";
import CookieConsent from "react-cookie-consent";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { check } from "../store/actions/UserAction";
import Brightness4RoundedIcon from "@mui/icons-material/Brightness4Rounded";
import useLocalStorage from "use-local-storage";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("user_aniwe_token"))
      localStorage.setItem("user_aniwe_token", "visitor");
    dispatch(check());
  }, []);

  const defaultDark = window.matchMedia(
    "(prefers-color-scheme: light)"
  ).matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "light" : "dark"
  );

  const switchTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };
  return (
    <div data-theme={theme}>
      <Navigbar />
      <main>{children}</main>
      <CookieConsent
        buttonText="J'accepte"
        location="bottom"
        buttonStyle={{
          background: "#0052d4",
          color: "white",
        }}
        expires={180}
        SameSite="Strict"
      >
        Nous utilisons les cookies de Google pour vous donner la meilleure
        expérience en ligne. En utilisant notre site, vous acceptez notre
        utilisation des cookies en conformité avec notre politique de cookie.{" "}
        <a href="/Mentions">En savoir plus.</a>
      </CookieConsent>
      <div className="dark-mode" onClick={switchTheme}>
        {" "}
        <Brightness4RoundedIcon
          sx={{ fontSize: 45 }}
          className="darkm-position darkm-style"
        />
      </div>
      <ScrollButton />
      <Footer />
    </div>
  );
};

export default MainLayout;
