import React from "react";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import { deleteMessage } from "../../store/actions/MessActions";
import Button from "react-bootstrap/Button";

const AdminMess = (props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const handleDelete = async (id) => {
    dispatch(deleteMessage(id));
    window.location.reload();
  };
  return (
    <>
      <Col md={6}>
        <section className="page-article">
          <h3 className="titre-theme text-center"> N°{item.id}</h3>

          <p>
            Societe : {item.societe}
            <br></br>
            Nom: {item.nom} / Prénom: {item.prenom}
            <br></br>
            Email : {item.email}
            <br></br>
            Tél : {item.tel} <br></br>
            <br></br>
            Message :<br></br> {item.message} <br></br>
          </p>
          <div className="text-center">
            <Button
              variant="outline-danger"
              type="submit"
              onClick={() => handleDelete(item.id)}
            >
              Supprimer
            </Button>{" "}
          </div>
        </section>
      </Col>
      <br></br>
      <br></br>
    </>
  );
};
export default AdminMess;
