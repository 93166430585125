/*
 * Import Actions { ... }
 * ********************** */
import * as Actions from "../actions/ActionsTypes";

/*
 * Selector
 * ******** */
const initialState = {
  flash: "",
  listReas: [],
  reas: {},
};

/*
 * Reducers
 * ******** */
export function ReaReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case Actions.GET_REA:
      return {
        ...state,
        flash: action.payload.flash,
        listReas: action.payload.dbReas,
      };
      case Actions.GET_REANEWS:
        return {
          ...state,
          flash: action.payload.flash,
          listReas: action.payload.dbReas,
        };
    case Actions.GETID_REA:
      return {
        ...state,
        flash: action.payload.flash,
        reas: action.payload.reas,
      };
    case Actions.POST_REA:
      return {
        ...state,
        flash: action.payload.flash,
        listReas: action.payload.dbReas,
      };
    case Actions.DELETE_REA:
      return {
        ...state,
        flash: action.payload.flash,
        listReas: action.payload.dbReas,
      };
  }
}

/*
 * Getters
 * ******* */