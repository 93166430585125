import React from "react";
import MainLayout from "../layouts/MainLayout";
import Offres from "../components/Offres";
import Produits from "../components/Produits";
import Header from "../components/core/HeaderServ";
import { Seo } from "../components/core/Seo";
const Creation = () => {
  return (
    <>
      <MainLayout>
      <Seo
          title="💻📲Aniwebat 🔵 Création de site web au Mans"
          description="Bienvenue sur le site web de Aniwebat. Nous sommes spécialisés dan la création de site web , la gestion de vos sites web et  l'animation de vos réseaux sociaux, près du Mans en Sarthe. Notre mission ? Donner vie à vos projets digitaux tout en garantissant une présence en ligne solide et pérenne.
          Avec Aniwebat, bâtissez l'avenir numérique de votre entreprise ! Vous pouvez nous contacter en remplissant notre formulaire de contact."
          type="website"
          canonical="https://aniwebat.fr/Creation"
        />
        <Header />
        <br></br>
        <h2 className="text-center fancy2">🌐 Création de sites web</h2>
        <Offres />
        <br></br>
        <br></br>
        <br></br>

        <h4 className="text-center fancy3 mb-2">Nos autres services</h4>

        <Produits />
      </MainLayout>
    </>
  );
};
export default Creation;
