import React from "react";
import Col from "react-bootstrap/Col";
import { urlImg } from "../../utils/url";
import { useNavigate } from "react-router-dom";
const AperçuRea = (props) => {
  const { item } = props;
  const navigate = useNavigate();
  const toReaID = async (societe) => {
    navigate("/realisations/" + item.societe, { state: { societe, item } });
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Col md={4} className="mb-5 mt-3">
        <figure className="text-center">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Empêche la navigation par défaut de l'élément <a>
              toReaID();
            }}
          >
            <img
              className="img-fluid img-fig"
              alt={`${item.societe + "site aniwebat"}`}
              src={`${urlImg + item.image}`}
            ></img>
          </a>
          <figcaption>
            {item.societe}
            <br></br>
            Merci pour leur confiance !
          </figcaption>
        </figure>
      </Col>
    </>
  );
};
export default AperçuRea;
