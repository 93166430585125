// Présentation fondateur
// Photo + texte
import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Presentation = () => {
  return (
    <div>
      <Container>
        <h3 className="text-center fancy3 ">L'équipe</h3>
        <Row>
          <Col md={6}>
            <p className="text-theme text-center">Eric Boisseau</p>

            <div className="d-flex justify-content-center mb-3">
              {" "}
              <a
                href="https://www.linkedin.com/in/eric-boisseau-8ba11216b/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="aniwebat eric boisseau"
                  className="avatar anim-float "
                  src="./images/eb.png"
                ></img>
              </a>
            </div>
            <p className="justif text-center">
              {" "}
              🎩 Dirigeant de l'entreprise Storeferm à Saint-Mars-la-Brière,
              Sarthe. Reconnu dans son secteur géographique comme un pro de la
              rénovation du bâtiment et surtout de la protection solaire, il
              aime et prend un très grand plaisir à partager avec tous ses
              coéquipiers dans cette grande aventure, l'entreprise ...
            </p>
          </Col>
          <Col md={6}>
            <p className="text-theme text-center">Lucas " Le Geek" Ledoux</p>
            <div className="d-flex justify-content-center  mb-3">
              {" "}
              <a
                href="https://www.linkedin.com/in/lucas-ledoux-244b0a175/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="aniwebat lucas ledoux"
                  className="avatar anim-float "
                  src="./images/ll.png"
                ></img>
              </a>
            </div>

            <p className="justif text-center">
              💻Développeur Web - Front-end & Back-end / 26 ans. Après une bonne
              expérience en gestion d'entreprise, je me tourne aujourd'hui vers
              mes premières passions que sont les nouvelles technologies à
              travers le developpement web et le webmastering.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Presentation;
