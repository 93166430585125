// Cards Produits
import React from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
const Produits = () => {
  return (
    <div>
      <Container>
        <Row className="mx-auto">
          <Col md={4}>
            <Card border="primary" className="text-center scale mb-4 mt-4">
              <Card.Header></Card.Header>
              <Card.Img
                alt="animation réseaux aniwebat"
                variant="top"
                src="../images/social.webp"
              />
              <Card.Body>
                <Card.Title className="text-theme">
                  Animation Réseaux
                </Card.Title>
                <Card.Text className="justif">
                  Sous-estimée et parfois méconnue, l’animation de vos pages sur
                  les réseaux sociaux est une action pourtant incontournable.
                </Card.Text>
                <br></br>
                <a href="/Animation" className="btn-produits text-center">
                  En savoir plus
                </a>
                <br></br>
                <br></br>
              </Card.Body>
              <Card.Footer className="card-footer text-white">
                Profitez-en
              </Card.Footer>
            </Card>
          </Col>
          {""}
          <Col md={4}>
            <Card border="primary" className="text-center scale mb-4 mt-4">
              <Card.Header></Card.Header>
              <Card.Img
                alt="creation site internet aniwebat"
                variant="top"
                src="../images/creation.webp"
              />
              <Card.Body>
                <Card.Title className="text-theme">
                  Création de sites internet
                </Card.Title>
                <Card.Text className="justif">
                  Un site internet qui correspond à vos besoins et adapté à tout
                  types d'écrans. Découvrez nos offres : du site "One page" au
                  site "Ultime"!
                </Card.Text>
                <br></br>
                <a href="/Creation" className="btn-produits text-center">
                  En savoir plus
                </a>
                <br></br>
                <br></br>
              </Card.Body>
              <Card.Footer className="card-footer text-white">
                Profitez-en
              </Card.Footer>
            </Card>
          </Col>
          <Col md={4}>
            <Card border="primary" className="text-center scale mb-4 mt-4">
              <Card.Header></Card.Header>
              <Card.Img
                alt="webmaster aniwebat"
                variant="top"
                src="../images/webmaster1.webp"
              />
              <Card.Body>
                <Card.Title className="text-theme">Webmastering</Card.Title>
                <Card.Text className="justif">
                  À l’heure où les entreprises ne cessent de se digitaliser, il
                  est essentiel que votre entreprise soit visible sur internet.
                </Card.Text>
                <br></br>
                <a href="/Webmaster" className="btn-produits text-center">
                  En savoir plus
                </a>
                <br></br>
                <br></br>
              </Card.Body>
              <Card.Footer className="card-footer text-white">
                Profitez-en
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Produits;
