import React from "react";
import FormContact from "../components/FormContact";
import MainLayout from "../layouts/MainLayout";
import Container from "react-bootstrap/Container";
import Header from "../components/core/HeaderContact";
import { Seo } from "../components/core/Seo";

const FormsContact = () => {
  return (
    <>
      <MainLayout>
        <Seo
          title="💻📲Aniwebat 🔵 Avoir son devis"
          description="Créée en 2023 par deux passionnés issus du monde du bâtiment, Aniwebat fusionne l'expertise technique du bâtiment avec le dynamisme du numérique. Nous sommes spécialisés dans la réalisation de sites web sur-mesure, l'animation de vos réseaux sociaux et le webmastering. Nous sommes principalement localisés en Sarthe à 20 Km du Mans. 
          Notre mission ? Donner vie à vos projets digitaux tout en garantissant une présence en ligne solide et pérenne.
           Avec Aniwebat, bâtissez l'avenir numérique de votre entreprise !"
          type="website"
          canonical="https://aniwebat.fr/Contact"
        />
        <Header />
        <Container>
          <h2 className="titre-theme mt-5 mb-5 text-center">
            Vous souhaitez développer votre présence sur le web ?{" "}
          </h2>
          <p className="text-center justif">
            Nous sommes à votre disposition pour toute demande de renseignements
            sur nos services. Vous pouvez aussi simplement remplir ce formulaire
            de contact. Nous vous répondrons le plus rapidement possible
          </p>
        </Container>
        <FormContact />
        <br></br>
        <p className="text-center justif note">
          .... Ah oui ! Et aussi, on vous a laissé une surprise si vous cliquez
          sur le 💖 tout en bas de la page.
        </p>
        <br></br>
      </MainLayout>
    </>
  );
};
export default FormsContact;
