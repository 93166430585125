import React from "react";
import MainLayout from "../layouts/MainLayout";
import Produits from "../components/Produits";
import MapAperçuRea from "../components/Realisation/MapAperçuRea";
import Header from "../components/core/Headerrea";
import { getReas } from "../store/actions/ReaActions";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Seo } from "../components/core/Seo";

const Realisations = () => {
  const dispatch = useDispatch();
  const listReas = useSelector((state) => state.reas.listReas);
  useEffect(() => {
    dispatch(getReas());
  }, []);
  return (
    <>
      <MainLayout>
        <Seo
          title="💻📲Aniwebat 🔵 Nos réalisations"
          description="Bienvenue sur Aniwebat.fr. Chez Aniwebat, nous comprenons l'importance d'un design web de qualité et d'une stratégie digitale efficace. Chaque réalisation est le fruit de notre passion et de notre expertise, transformant les visions de nos clients en réalités digitales impressionnantes. Découvrez ici quelques-unes de nos créations les plus marquantes, témoignant de notre engagement à créer des sites web qui attirent, engagent et convertissent. Aniwebat, bâtissez l'avenir numérique de votre entreprise !"
          type="website"
          canonical="https://aniwebat.fr/realisations"
        />
        <Header />
        <MapAperçuRea list={listReas} />
        <h4 className="text-center fancy3 mb-2">Nos Services</h4>
        <Produits />
      </MainLayout>
    </>
  );
};
export default Realisations;
