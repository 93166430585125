import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Exemples from "./Exemples";
const MapExemple = (props) => {
    const { list } = props;
  return (
    <div className="mb-5 mt-2">
      <Container fluid>
        <h2 className="text-center fancy3">Nos réalisations réçentes</h2>
        <Row className="mt-5">
        {list.length > 0 &&
            list.map((item) => {
              return <Exemples item={item} key={item.id} />;
            })}
        </Row>
        <br></br>
        <div className="text-center">
          <a className="btn-offre" href="/Realisations">
            👉 Voir toutes nos réalisations
          </a>
        </div>
        <br></br>
      </Container>
    </div>
  );
};
export default MapExemple;
