import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useDispatch } from "react-redux";
import { login, check } from "../../store/actions/UserAction";
import { useNavigate } from "react-router";

const Connexion = () => {
  const navigate = useNavigate();
  const [mail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  // ici la fonction est asynchrone
  const handleForm = async (e) => {
    e.preventDefault();
    if (mail && password) {
      dispatch(login({ mail, password}));
      setEmail("");
      setPassword("");
      dispatch(check());
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <div>
      <h2 className="titre-theme text-center">Se connecter</h2>
      <Container>
        <Form onSubmit={(e) => handleForm(e)}>
          <Col md={12} className="mx-auto">
            <FloatingLabel controlId="email1" label="Email" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                maxLength={255}
                value={mail}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FloatingLabel>
          </Col>
          <Col md={12} className="mx-auto">
            <FloatingLabel
              controlId="password2"
              label="Mot de passe"
              className="mb-3"
            >
              <Form.Control
                type="password"
                placeholder="Mot de passe"
                maxLength={255}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FloatingLabel>
          </Col>
          <br></br>
          <div className="text-center">
            <Button className="mb-5" type="submit">
              Envoyer
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};
export default Connexion;
