import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useDispatch } from "react-redux";
import { createMessage, getMessage } from "../store/actions/MessActions";
import ReCAPTCHA from "react-google-recaptcha";
import confetti from "canvas-confetti";

const FormContact = () => {
  const [societe, setSociete] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const captchaRef = useRef(null);

  const handleClick = () => {
    var myCanvas = document.createElement("canvas");
    document.body.appendChild(myCanvas);

    var end = Date.now() + 15 * 500;

    var colors = ["#3085C3", "#8CC0DE", "#525FE1"];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  };

  const handleForm = async (e) => {
    e.preventDefault();
    const captcha = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (societe && nom && prenom && email && tel && message && captcha) {
      dispatch(
        createMessage({
          societe,
          nom,
          prenom,
          email,
          tel,
          message,
          captcha,
        })
      );
      setSociete("");
      setNom("");
      setPrenom("");
      setEmail("");
      setTel("");
      setMessage("");
      dispatch(getMessage());
      window.alert("Message envoyé avec succès  ! ");
      handleClick();
    }
  };

  return (
    <div className="mt-5">
      <Container className="form-border">
        <h3 className="text-center fancy mb-4 mt-2">Contactez-nous 📬</h3>
        <Col md={12}>
          <Form onSubmit={(e) => handleForm(e)}>
            <Col md={12} className="mx-auto">
              <FloatingLabel
                controlId="societe"
                label="Le nom de votre entreprise*"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Le nom de votre entreprise"
                  value={societe}
                  onChange={(e) => setSociete(e.target.value)}
                  maxLength={255}
                  required
                />
              </FloatingLabel>
            </Col>
            <Row>
              <Col md={6}>
                <FloatingLabel
                  controlId="name"
                  label="Votre nom*"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Nom*"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    maxLength={100}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="prenom"
                  label="Votre prénom*"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Prénom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    maxLength={100}
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <FloatingLabel
              controlId="email"
              label="Votre email*"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                maxLength={255}
                required
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="phone"
              label="Votre N° de téléphone*"
              className="mb-3"
            >
              <Form.Control
                type="phone"
                placeholder="N° de téléphone"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                maxLength={15}
                required
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="textarea"
              label="Décrivez nous votre projet"
              className="mb-3"
            >
              <Form.Control
                as="textarea"
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FloatingLabel>

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_KEY}
              ref={captchaRef}
              render="explicit"
              theme="dark"
            ></ReCAPTCHA>
            <br></br>
            <p className="note justif">
              * ce sont des champs obligatoires pour répondre au mieux à vos
              demandes.
            </p>
            <Form.Check
              type="checkbox"
              id="checkbox"
              label={
                <>
                  {" "}
                  En envoyant ce formulaire, vous consentez à la collecte de vos
                  données personnelles afin que votre demande puisse être
                  traîtée. Voir nos{" "}
                  <a href="/Politiques">Politiques de Confidentialité</a>{" "}
                </>
              }
              required
            />

            <br></br>
            <div className="text-center mb-2">
              <button className="btn-nav" type="submit">
                Envoyer
              </button>
            </div>
            <br></br>
          </Form>
        </Col>
      </Container>
      <br></br>
    </div>
  );
};
export default FormContact;
