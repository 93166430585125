import React from "react";
import Container from "react-bootstrap/Container";
import AdminRéa from "./AdminRéa";
import Row from "react-bootstrap/Row";

const AdminListeRéa = (props) => {
  const { list } = props;
  return (
    <>
      <Container>
        <h2 className="fancy2 text-center"> 🎨Nos Réalisations</h2>
        <br></br>
        <br></br>
        <Row>
          {list.length > 0 &&
            list.map((item) => {
              return <AdminRéa item={item} key={item.id} />;
            })}
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default AdminListeRéa;
