import React from "react";
import Navigbar from "../components/core/Navigbar";

const NotFound = () => {
  return (
    <div>
      <Navigbar />
      <br></br>
      <h2 className="titre-theme text-center mt-5"> Erreur 404</h2>
      <p className="text-theme text-center">
        {" "}
        Veuillez-nous excuser cette page est en construction ou n'existe pas.
      </p>
      <br></br>
      <div className="text-center">
        <img className="img-fluid" alt="logo aniwebat" src="./logo2.png"></img>
      </div>
      <br></br>
      <br></br>
      <div className="text-center">
        <a className="btn-nav" href="/">
          Vers le site
        </a>
      </div>
    </div>
  );
};
export default NotFound;
