import React, { useState, useRef  } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { createUser } from "../../store/actions/UserAction";
const Compte = () => {
  const [mail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const captchaRef = useRef(null);

  const handleForm = async (e) => {
    e.preventDefault();
    const captcha = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (mail && password && captcha) {
      dispatch(createUser({ mail, password, captcha }));
      setEmail("");
      setPassword("");
      window.alert("Compte créé !");
    }
  };
  return (
    <div>
      <h3 className="titre-theme text-center">Créer un compte</h3>
      <Container>
        <Form onSubmit={(e) => handleForm(e)}>
          <Col md={12} className="mx-auto">
            <FloatingLabel controlId="email2" label="Email" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                value={mail}
                onChange={(e) => setEmail(e.target.value)}
                maxLength={255}
                required
              />
            </FloatingLabel>
          </Col>
          <Col md={12} className="mx-auto">
            <FloatingLabel
              controlId="password1"
              label="Mot de passe"
              className="mb-3"
            >
              <Form.Control
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                maxLength={255}
                required
              />
            </FloatingLabel>
          </Col>
          <Col md={12}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_KEY}
              render="explicit"
              ref={captchaRef}
              theme="dark"
            ></ReCAPTCHA>
          </Col>
          <br></br>
          <div className="text-center">
            <Button className="mb-5" type="submit">
              Envoyer
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};
export default Compte;
