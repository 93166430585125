import Home from "./pages/Home";
import Nous from "./pages/Contact";
import Form from "./pages/FormsContact";
import Animation from "./pages/Animation";
import Creation from "./pages/Creation";
import Webmaster from "./pages/Webmaster";
import Blog from "./pages/Blog";
import BlogID from "./pages/BlogID";
import Mentions from "./pages/Mentions";
import Politiques from "./pages/Politiques";
import NotFound from "./pages/NotFound";
//import EnConstru from "./pages/EnConstru";
import Realisations from "./pages/Realisations";
import ReaID from "./pages/ReaID";

//-----------------------Admin----------------
import Register from "./pages/Admin/Register";
import Gestion from "./pages/Admin/Gestion";
import AdminRéa from "./pages/Admin/GestionRéa";
import AdminArt from "./pages/Admin/GestionArt";
import { BrowserRouter, Routes, Route } from "react-router-dom";


const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Register />} />
      <Route path="/Gestion" exact element={<Gestion />} />
      <Route path="/Realisations" exact element={<AdminRéa />} />
      <Route path="/Blog" exact element={<AdminArt />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function App() {
  return (
    <BrowserRouter>
   
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/Nous" exact element={<Nous />} />
          <Route path="/Animation" exact element={<Animation />} />
          <Route path="/Creation" exact element={<Creation />} />
          <Route path="/Webmaster" exact element={<Webmaster />} />
          <Route path="/Contact" exact element={<Form />} />
          <Route path="/Mentions" exact element={<Mentions />} />
          <Route path="/Politiques" exact element={<Politiques />} />
          <Route path="/Realisations" exact element={<Realisations />} />
          <Route path="/Realisations/:societe" exact element={<ReaID />} />
          <Route path="/Blog" exact element={<Blog />} />
          <Route path="/Blog/:titre" exact element={<BlogID />} />
          <Route path="/Admin/*" exact element={<AdminRoutes />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
     
    </BrowserRouter>
  );

  /*function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<EnConstru />} />
        <Route path="/Admin/*" exact element={<AdminRoutes />} />
        <Route path="*" element={<EnConstru />} />
      </Routes>
    </BrowserRouter>
  );*/
}

export default App;
