import React from "react";
import Container from "react-bootstrap/Container";
import Articles from "./Articles";
import Row from "react-bootstrap/Row";

const ListeArt = (props) => {
  const { list } = props;
  return (
    <>
      <Container>
        <Row>
          {list.length > 0 &&
            list.map((item) => {
              return <Articles item={item} key={item.id} />;
            })}
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default ListeArt;
