/*
 * React
 * ***** */
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

/*
 * Reducers
 * ******** */
import { MessReducer } from "./reducers/MessReducer";
import { ReaReducer } from "./reducers/ReaReducer";
import { UserReducer } from "./reducers/UserReducer";
import { ArtReducer } from "./reducers/ArtReducer";

/*
 * All (Root) Reducers
 * ******************* */
const rootReducer = combineReducers({
  messages: MessReducer,
  reas: ReaReducer,
  users: UserReducer,
  arts: ArtReducer,
});

/*
 * Store export
 * ************ */
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
