import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useDispatch } from "react-redux";
import { createRea } from "../../store/actions/ReaActions";

const AdminGestionRéa = () => {
  const [stateImgUpload, setStateImgUpload] = useState("");
  const [imgPreview, setPreview] = useState("");
  const [imgSelect, setSelect] = useState("");
  const [image, setImg] = useState("");
  const [societe, setSoc] = useState("");
  const [lien, setLien] = useState("");
  const [description, setDesc] = useState("");
  const [technologie, setTech] = useState("");

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setStateImgUpload("Image non enregistrée");
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setSelect(true);
        setPreview(reader.result);
        setImg(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    if (!image) {
      setStateImgUpload("image obligatoire");
    } else {
      setStateImgUpload("");
    }
    const dataRea = {
      societe,
      lien,
      description,
      technologie,
    };
    const formdata = new FormData();
    Object.entries(dataRea).forEach(([cle, valeur]) => {
      formdata.append(cle, valeur);
    });
    if (imgSelect) {
      formdata.append("image", image);
    }
    setSelect(false);
    dispatch(createRea(formdata));
    window.location.reload();
    alert("Ajout Réalisation !");
  };
  return (
    <div>
      <Container>
        <Form onSubmit={(e) => handleForm(e)}>
          <Col md={12}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Choisir votre image</Form.Label>
              <Form.Control
                type="file"
                className="mb-3"
                accept="image/*"
                onChange={handleInputChange}
              />
            </Form.Group>
            {imgSelect ? (
              <img
                src={`${imgPreview}`}
                width="200"
                height="200"
                className="img-fluid"
                alt="image aniwebat"
              />
            ) : (
              <img
                src={`${imgPreview}`}
                width="200"
                height="200"
                className="img-fluid"
                alt="image aniwebat"
              />
            )}
            {{ stateImgUpload } && (
              <p className="text-danger">{stateImgUpload}</p>
            )}
          </Col>
          <Row>
            <Col md={6}>
              <FloatingLabel controlId="societe" label="societe">
                <Form.Control
                  type="text"
                  placeholder="Societe"
                  className="mb-3"
                  maxLength={255}
                  value={societe}
                  onChange={(e) => setSoc(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel controlId="lien" label="Lien">
                <Form.Control
                  type="text"
                  placeholder="Lien"
                  className="mb-3"
                  maxLength={255}
                  value={lien}
                  onChange={(e) => setLien(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Col sm={12}>
            <FloatingLabel controlId="Description" label="Description">
              <Form.Control
                as="textarea"
                rows={4}
                className="mb-3"
                value={description}
                onChange={(e) => setDesc(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col sm={12}>
            <FloatingLabel
              controlId="Détails techniques"
              label="Détails techniques"
            >
              <Form.Control
                as="textarea"
                rows={4}
                className="mb-3"
                value={technologie}
                onChange={(e) => setTech(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <div className="text-center">
            <Button className="mb-5" type="submit">
              Envoyer
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};
export default AdminGestionRéa;
