import React from "react";
import MainLayout from "../layouts/MainLayout";
import { getArt } from "../store/actions/ArtAction";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListeArt from "../components/Blog/ListeArt";
import Header from "../components/core/HeaderBlog";
import { Seo } from "../components/core/Seo";
const Blog = () => {
  const dispatch = useDispatch();
  const listArts = useSelector((state) => state.arts.listArts);
  useEffect(() => {
    dispatch(getArt());
  }, []);
  return (
    <>
      <MainLayout>
        <Seo
          title="💻📲Aniwebat 🔵 Le Blog"
          description="Bienvenue sur Aniwebat.fr. Que vous soyez un entrepreneur en manque de temps, un dirigeant / chef d'entreprise curieux, ou encore un passionné cherchant à rester à la pointe des dernières tendances, vous êtes au bon endroit. Plongez avec nous dans ce voyage à travers les innovations qui façonnent le paysage numérique d'aujourd'hui. Avec Aniwebat au Mans, donnez à votre entreprise le rayonnement digital qu'elle mérite"
          type="website"
          canonical="https://aniwebat.fr/Blog"
        />
        <Header />
        <br></br>
        <h2 className="text-center fancy2 mt-4 mb-3">📜Le Blog</h2>
        <ListeArt list={listArts} />
      </MainLayout>
    </>
  );
};
export default Blog;
