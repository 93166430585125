import React from "react";
import Produits from "../components/Produits";
import Nous from "../components/Presentation";
import Bienvenue from "../components/Bienvenue";
import MainLayout from "../layouts/MainLayout";
import MapExemple from "../components/Realisation/MapExemple";
import Header from "../components/core/Header";
import { getReaNews } from "../store/actions/ReaActions";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Seo } from "../components/core/Seo";

const Home = () => {
  const dispatch = useDispatch();
  const listReas = useSelector((state) => state.reas.listReas);
  useEffect(() => {
    dispatch(getReaNews());
  }, []);

  return (
    <div>
      <MainLayout>
        <Seo
          title="💻📲Aniwebat 🔵 Création de sites web , animation de vos réseaux sociaux et Wemastering au Mans"
          description="Bienvenue sur le site web de Aniwebat. Nous sommes spécialisés dan la création de site web , la gestion de vos sites web et  l'animation de vos réseaux sociaux, près du Mans en Sarthe. Notre mission ? Donner vie à vos projets digitaux tout en garantissant une présence en ligne solide et pérenne.
          Avec Aniwebat, bâtissez l'avenir numérique de votre entreprise ! Vous pouvez nous contacter en remplissant notre formulaire de contact."
          type="website"
          canonical="https://aniwebat.fr/"
        />
        <Header />
        <br></br>
        <Bienvenue />
        <Produits />
        <br></br>

        <MapExemple list={listReas} />

        <Nous />
        <br></br>
        <br></br>
        <div className="text-center mb-5">
          <h4 className="titre-theme mt-5 mb-5">
            Vous souhaitez développer votre présence sur le web ?{" "}
          </h4>
          <a href="/Contact" className="btn-nav mx-auto text-center">
            Contactez Nous
          </a>
        </div>
        <br></br>
        <br></br>
        <p className="text-center justif note">
          .... Ah oui ! Et aussi, on vous a laissé une surprise si vous cliquez
          sur le 💖 tout en bas de la page.
        </p>
        <br></br>
      </MainLayout>
    </div>
  );
};
export default Home;
