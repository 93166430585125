import React from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import confetti from "canvas-confetti";

const Footer = () => {
  const handleClick = () => {
    var myCanvas = document.createElement("canvas");
    document.body.appendChild(myCanvas);

    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  };
  return (
    <footer>
      <div className="text-center">
        <Nav.Link href="/">
          <img
            src="/logo.png"
            className="d-inline-block align-top mt-3"
            alt="aniwebat logo"
          />
        </Nav.Link>
      </div>
      <Container className="mt-4" fluid>
        <Row>
          <Col md={4}>
            <p className="fancy2 text-center">Nous Contacter</p>
            <div className="text-center">
              <a className="lien-footer" href="tel:  02 43 23 49 59">
                <PhoneIcon />
                02 43 23 49 59
              </a>{" "}
              <br></br>
              <a
                href="mailto: lucasledoux@aniwebat.fr"
                target="_blank"
                rel="noreferrer"
                className="lien-footer"
              >
                <EmailIcon sx={{ fontSize: 30 }} />
                {""}
                Nous contacter
              </a>
            </div>
          </Col>
          <Col md={4}>
            <p className="fancy2 text-center">Adresse</p>
            <div className="text-center">
              <a
                href="https://goo.gl/maps/AuAueX7pkfV95gE8A"
                target="_blank"
                rel="noreferrer"
                className="lien-footer"
              >
                <FmdGoodIcon sx={{ fontSize: 30 }} /> 15 Chemin de Champagné,
                72470 Saint-Mars-la-Brière
              </a>
            </div>
          </Col>
          <Col md={4}>
            <p className="fancy2 text-center">Nos réseaux sociaux</p>
            <div className="text-center">
              <a
                className="socials"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100093181869748"
              >
                <FacebookIcon sx={{ fontSize: 30 }} />
              </a>
              <a
                className="socials"
                target="_blank"
                href="https://www.instagram.com/aniwebat/"
              >
                <InstagramIcon sx={{ fontSize: 30 }} />
              </a>

              <a
                className="socials"
                target="_blank"
                href="https://www.linkedin.com/company/aniwebat/"
              >
                <LinkedInIcon sx={{ fontSize: 30 }} />
              </a>
              <a
                className="socials"
                target="_blank"
                href="https://maps.app.goo.gl/Kzvrq77wSWEBgEiH6"
              >
                <GoogleIcon sx={{ fontSize: 30 }} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="text-center">
        <a className="lien-footer" href="/Politiques">
          Politiques de confidentialité
        </a>
        {""} - {""}
        <a className="lien-footer" href="/Mentions">
          Mentions légales
        </a>{" "}
        <br></br>
        Créer avec{" "}
        <a onClick={handleClick}>
          {" "}
          <FavoriteIcon className="heart" fontSize="small" />
        </a>{" "}
        par {""}
        Aniwebat
      </div>{" "}
    </footer>
  );
};
export default Footer;
