import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Header = () => {
  return (
    <header>
      <Container fluid>
        <Row>
          <Col md={6}>
            <br></br>
            <h1 className="color-header">Aniwebat,</h1>
            <p className="text-header">
              {" "}
              Bienvenue dans l'ère numérique où le web évolue à une vitesse
              vertigineuse !
            </p>
            <p className="bienvenue">
              Chaque jour, de nouvelles technologies émergent, redéfinissant les
              frontières du développement web, transformant la manière dont nous
              interagissons sur les réseaux sociaux et repoussant les limites de
              ce qu'un webmaster peut accomplir. Que vous soyez un entrepreneur
              en manque de temps, un dirigeant / chef d'entreprise curieux, ou
              encore un passionné cherchant à rester à la pointe des dernières
              tendances, vous êtes au bon endroit. Plongez avec nous dans ce
              voyage à travers les innovations qui façonnent le paysage
              numérique d'aujourd'hui. Découvrez les outils, les techniques et
              les stratégies qui vous permettront non seulement de suivre le
              rythme, mais aussi de vous démarquer dans cet univers en constante
              évolution.<br></br>Avec{" "}
              <span className="text-theme">Aniwebat</span>, donnez à votre
              entreprise le rayonnement digital qu'elle mérite.
            </p>
            <br></br>
            <div className="d-flex justify-content-sm-around">
              <a href="/Contact" className="btn-nav text-center">
                Prendre RDV
              </a>
              {""}
              <a href="/Creation" className="btn-nav text-center">
                Voir nos Offres
              </a>
            </div>
          </Col>
          <Col md={6}>
            <img
              className="img-header"
              alt="animation aniwebat.fr"
              src="../images/anim_blog.png"
            ></img>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
export default Header;
