/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
  POST_MESSAGE,
  GET_MESSAGE,
  DELETE_MESSAGE,
  GETID_MESSAGE,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */
// getAll Messages
export const getMessage = (data) => {
  return (dispatch) => {
    return api
      .get("/Admin/Messages")
      .then((res) => {
        dispatch({ type: GET_MESSAGE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// getID Article
export const getMessageID = (id) => {
  return (dispatch) => {
    return api
      .get(`/Admin/Messages/${id}`)
      .then((res) => {
        dispatch({ type: GETID_MESSAGE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// Create Message
export const createMessage = (data) => {
  return (dispatch) => {
    return api
      .post("/Contact", data)
      .then((res) => {
        dispatch({ type: POST_MESSAGE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// Delete Article
export const deleteMessage = (id) => {
  return (dispatch) => {
    return api
      .delete(`/Admin/Messages/${id}`)
      .then((res) => {
        dispatch({ type: DELETE_MESSAGE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
