import React from "react";
import Container from "react-bootstrap/Container";
import AdminArt from "./AdminArt";
import Row from "react-bootstrap/Row";

const AdminListeArt = (props) => {
  const { list } = props;
  return (
    <>
      <Container>
        <h2 className="fancy2 text-center"> 📜Le Blog</h2>
        <br></br>
        <br></br>
        <Row>
          {list.length > 0 &&
            list.map((item) => {
              return <AdminArt item={item} key={item.id} />;
            })}
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default AdminListeArt;
