import React from "react";
import Container from "react-bootstrap/Container";
import AperçuRea from "./AperçuRea";
import Row from "react-bootstrap/Row";

const MapAperçuRea = (props) => {
  const { list } = props;
  return (
    <>
      <Container fluid>
        <br></br>
        <h2 className="fancy2 text-center"> 🎨Nos Réalisations</h2>

        <Row>
          {list.length > 0 &&
            list.map((item) => {
              return <AperçuRea item={item} key={item.id} />;
            })}
        </Row>

        <br></br>
      </Container>
    </>
  );
};
export default MapAperçuRea;
