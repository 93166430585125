import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Entreprise = () => {
  return (
    <div>
      <Container>
        <br></br>
        <h2 className="text-center fancy2">Qui-Sommes-Nous ?</h2>
        <Row className="mt-4">
          <Col md={6}>
            <h3 className="text-center fancy">L'entreprise</h3>
            <br></br>
            <p className="justif">
              <span className="text-theme"> "Aniwebat"</span> est née suite à
              une d'idée d'Eric Boisseau ( Dirigeant de Storeferm à Saint-Mars-la-Brière) et au besoin des entreprises du bâtiment de
              développer leur présence sur les réseaux et sur le web. Notre
              objectif est de mettre au point des solutions de qualité qui nous
              permettent de répondre à vos besoins souvent très différents.
              <br></br>
              <br></br>
              <span className="text-theme">Eric</span> mettra à profit son
              expérience et son expertise dans le bâtiment et{" "}
              <span className="text-theme">Lucas</span> s'occupera de tous les
              aspects du web : Gestion de vos réseaux sociaux , Webmastering et,
              bien sûr, la création de sites web.
            </p>
          </Col>
          <Col md={6}>
            <br></br>
            <br></br>
            <div className="d-flex justify-content-center">
              <figure className="swing">
                <img
                  src="./logo2.png"
                  alt="logo aniwebat"
                  className="img-fluid"
                ></img>
              </figure>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Entreprise;
