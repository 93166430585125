import React from "react";
import AdminListeMess from "../../components/Admin/AdminListeMess";
import { useEffect } from "react";
import { getMessage } from "../../store/actions/MessActions";
import AdminLayout from "../../layouts/AdminLayout";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Seo } from "../../components/core/Seo";

const Gestion = () => {
  const dispatch = useDispatch();
  const listMessages = useSelector((state) => state.messages.listMessages);
  useEffect(() => {
    dispatch(getMessage());
  }, []);

  const navigate = useNavigate();
  
  const logout = () => {
    localStorage.setItem("user_aniwe_token", "visitor");
    navigate("/");
    window.location.reload();
  };

  return (
    <div>
      <AdminLayout>
      <Seo
          title="💻📲Adminwebat 🔵"
          description="Bienvenue sur le site web de Aniwebat. Nous sommes spécialisés dan la création de site web , la gestion de vos sites web et  l'animation de vos réseaux sociaux, près du Mans en Sarthe. Notre mission ? Donner vie à vos projets digitaux tout en garantissant une présence en ligne solide et pérenne.
          Avec Aniwebat, bâtissez l'avenir numérique de votre entreprise ! Vous pouvez nous contacter en remplissant notre formulaire de contact."
          type="website"
        />
        <Container>
          <div className=" text-center mt-3">
            <Button variant="danger" type="button" onClick={logout}>
              {" "}
              Se déconnecter
            </Button>
          </div>
          <br></br>
          <h1 className="titre-theme text-center">
            {" "}
            Accueil & Gestion contenus
          </h1>
          <br></br>
          <Row>
            <Col md={6}>
              <div className="text-center">
                <a className="btn-nav" href="/Admin/Realisations">
                  Ajouter une réalisation
                </a>
                {""}
              </div>
            </Col>
            <Col md={6}>
              <div className="text-center">
                {""}
                <a className="btn-nav" href="/Admin/Blog">
                  Ajouter un article au Blog
                </a>
              </div>
            </Col>
          </Row>
          <h2 className="titre-theme text-center mt-5 mb-5">
            {" "}
            Fiches Contacts
          </h2>
        </Container>
        <AdminListeMess list={listMessages} />
      </AdminLayout>
    </div>
  );
};
export default Gestion;
