import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { getArt } from "../../store/actions/ArtAction";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminListeArt from "../../components/Admin/AdminListeArt";
import AdminGestionArt from "../../components/Admin/AdminGestionArt";
import { Seo } from "../../components/core/Seo";

const GestionArt = () => {
  const dispatch = useDispatch();
  const listArts = useSelector((state) => state.arts.listArts);
  useEffect(() => {
    dispatch(getArt());
  }, []);
  return (
    <div>
      <AdminLayout>
      <Seo
          title="💻📲Adminwebat 🔵"
          description="Bienvenue sur le site web de Aniwebat. Nous sommes spécialisés dan la création de site web , la gestion de vos sites web et  l'animation de vos réseaux sociaux, près du Mans en Sarthe. Notre mission ? Donner vie à vos projets digitaux tout en garantissant une présence en ligne solide et pérenne.
          Avec Aniwebat, bâtissez l'avenir numérique de votre entreprise ! Vous pouvez nous contacter en remplissant notre formulaire de contact."
          type="website"
        />
        {" "}
        <br></br>
        <div className="text-center">
          <a className="btn-nav" href="/Admin/Gestion">
            Revenir en arrière
          </a>
        </div>
        <br></br>
        <AdminGestionArt />
        <br></br>
        <AdminListeArt list={listArts} />
      </AdminLayout>
    </div>
  );
};
export default GestionArt;
