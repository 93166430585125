import React from "react";
import MainLayout from "../layouts/MainLayout";
import Container from "react-bootstrap/Container";
const Politiques = () => {
  return (
    <MainLayout>
      <Container>
        <h2 className="titre-theme justif text-center mt-2">
          Politiques de confidentialité
        </h2>
        <p className="text-theme text-center">Aniwebat.fr</p>
        <h3 className="justif">ARTICLE 1 : PRÉAMBULE </h3>
        <p className="justif">
          {" "}
          La présente politique de confidentialité a pour but d’informer les
          utilisateurs du site : • Sur la manière dont sont collectées leurs
          données personnelles. Sont considérées comme des données personnelles,
          toute information permettant d’identifier un utilisateur. A ce titre,
          il peut s’agir : de ses noms et prénoms, de son âge, de son adresse
          postale ou email, de sa localisation ou encore de son adresse IP
          (liste non-exhaustive) ;
          <li>Sur les droits dont ils disposent concernant ces données ;</li>
          <li>
            {" "}
            Sur la personne responsable du traitement des données à caractère
            personnel collectées et traitées ;
          </li>{" "}
          <li>Sur les destinataires de ces données personnelles ;</li>{" "}
          <li>
            {" "}
            Sur la politique du site en matière de cookies. Cette politique
            complète les mentions légales et les Conditions Générales
            d’Utilisation consultables par les utilisateurs à l’adresse suivante
            : <a href="/Mentions">Mentions légales</a> <br></br>
          </li>
          <br></br>
          <br></br>
          <h3 className="justif">
            ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES
            DONNÉES PERSONNELLES{" "}
          </h3>
          <p className="justif">
            Conformément à l’article 5 du Règlement européen 2016/679, les
            données à caractère personnel sont :{" "}
            <li>
              Traitées de manière licite, loyale et transparente au regard de la
              personne concernée ;{" "}
            </li>
            <li>
              Collectées pour des finalités déterminées (cf. Article 3.1 des
              présentes), explicites et légitimes, et ne pas être traitées
              ultérieurement d'une manière incompatible avec ces finalités ;{" "}
            </li>
            <li>
              Adéquates, pertinentes et limitées à ce qui est nécessaire au
              regard des finalités pour lesquelles elles sont traitées ;{" "}
            </li>
            <li>
              Exactes et, si nécessaire, tenues à jour. Toutes les mesures
              raisonnables doivent être prises pour que les données à caractère
              personnel qui sont inexactes, eu égard aux finalités pour
              lesquelles elles sont traitées, soient effacées ou rectifiées sans
              tarder ;{" "}
            </li>
            <li>
              Conservées sous une forme permettant l'identification des
              personnes concernées pendant une durée n'excédant pas celle
              nécessaire au regard des finalités pour lesquelles elles sont
              traitées ;{" "}
            </li>
            <li>
              Traitées de façon à garantir une sécurité appropriée des données
              collectées, y compris la protection contre le traitement non
              autorisé ou illicite et contre la perte, la destruction ou les
              dégâts d'origine accidentelle, à l'aide de mesures techniques ou
              organisationnelles appropriées.{" "}
            </li>
          </p>
          <br></br>
          <p className="justif">
            Le traitement n'est licite que si, et dans la mesure où, au moins
            une des conditions suivantes est remplie :{" "}
          </p>
          <li>
            La personne concernée a consenti au traitement de ses données à
            caractère personnel pour une ou plusieurs finalités spécifiques ;
          </li>
          <li>
            Le traitement est nécessaire à l'exécution d'un contrat auquel la
            personne concernée est partie ou à l'exécution de mesures
            précontractuelles prises à la demande de celle-ci ;{" "}
          </li>
          <li>
            Le traitement est nécessaire au respect d'une obligation légale à
            laquelle le responsable du traitement est soumis ;{" "}
          </li>
          <li>
            Le traitement est nécessaire à la sauvegarde des intérêts vitaux de
            la personne concernée ou d'une autre personne physique ;{" "}
          </li>
          <li>
            Le traitement est nécessaire à l'exécution d'une mission d'intérêt
            public ou relevant de l'exercice de l'autorité publique dont est
            investi le responsable du traitement ;{" "}
          </li>
          <li>
            Le traitement est nécessaire aux fins des intérêts légitimes
            poursuivis par le responsable du traitement ou par un tiers, à moins
            que ne prévalent les intérêts ou les libertés et droits fondamentaux
            de la personne concernée qui exigent une protection des données à
            caractère personnel, notamment lorsque la personne concernée est un
            enfant.{" "}
          </li>
        </p>
        <br></br>
        <h4 className="justif">
          ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS
          LE CADRE DE LA NAVIGATION SUR LE SITE{" "}
        </h4>
        <h4 className="justif">Article 3.1 : Données collectées </h4>
        <p className="justif">
          Les données personnelles collectées dans le cadre de notre activité
          sont les suivantes : noms, prénoms, téléphones et mails <br></br> La
          collecte et le traitement de ces données répond à la (aux) finalité(s)
          suivante(s) : Recontacter la personne le plus rapidement possible /
          Demande de devis.
        </p>
        <h4 className="justif">Article 3.2 : Mode de collecte des données </h4>
        <p className="justif">
          Lorsque vous utilisez notre site, sont automatiquement collectées les
          données suivantes : Données pour Google. D’autres données personnelles
          sont collectées lorsque vous effectuez les opérations suivantes sur la
          plateforme : noms, prénoms, téléphones et mails. Afin de recontacter
          la personne le plus rapidement possible / Demande de devis. Elles sont
          conservées par le responsable du traitement dans des conditions
          raisonnables de sécurité, pour une durée de : 6 mois. La société est
          susceptible de conserver certaines données à caractère personnel
          au-delà des délais annoncés ci-dessus afin de remplir ses obligations
          légales ou réglementaires.
        </p>
        <h4 className="justif">Article 3.3 : Hébergement des données </h4>
        <p className="justif">
          Le site Aniwebat est hébergé par : EV@NE'SARTHE, 15 Chemin de
          Champagné ,72470-Saint-Mars-la-Brière,{" "}
          <a href="mailto:lucasledoux@aniwebat.fr">lucasledoux@aniwebat.fr</a>
        </p>
        <br></br>
        <h3 className="justif">
          ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA
          PROTECTION DES DONNÉES{" "}
        </h3>
        <h4 className="justif">
          Article 4.1 : Le responsable du traitement des données{" "}
        </h4>
        <p className="justif">
          Les données à caractère personnelles sont collectées par EV@NE'SARTHE,
          Société à responsabilité au capital de 7700 Euros, dont le numéro
          d’immatriculation est le 452 056 500 R.C.S Le Mans. Le responsable du
          traitement des données à caractère personnel peut être contacté de la
          manière suivante : Par courrier à l’adresse : 15 Chemin de
          Champagné,72470 - Saint-Mars-la-Brière ; Par téléphone, au 0243891442
          ; Par mail :{" "}
          <a href="mailto:lucasledoux@aniwebat.fr">lucasledoux@aniwebat.fr</a>.{" "}
        </p>
        <h4 className="justif">
          Article 4.2 : Le délégué à la protection des données{" "}
        </h4>
        <p className="justif">
          Le délégué à la protection des données de l’entreprise ou du
          responsable est : Lucas Ledoux, 15 Chemin de Champagné,
          72470-Saint-Mars-la-Brère,
          <a href="mailto:lucasledoux@aniwebat.fr">
            lucasledoux@aniwebat.fr
          </a>. Si vous estimez, après nous avoir contactés, que vos droits
          “Informatique et Libertés”, ne sont pas respectés, vous pouvez
          adresser une information à la CNIL.{" "}
        </p>
        <br></br>
        <br></br>
        <h3 className="justif">
          ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE
          TRAITEMENT DES DONNÉES{" "}
        </h3>
        <p className="justif">
          Tout utilisateur concerné par le traitement de ses données
          personnelles peut se prévaloir des droits suivants, en application du
          règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi
          78-17 du 6 janvier 1978) :{" "}
          <li>
            Droit d’accès, de rectification et droit à l’effacement des données
            (posés respectivement aux articles 15, 16 et 17 du RGPD) ;{" "}
          </li>
          <li>Droit à la portabilité des données (article 20 du RGPD) ; </li>
          <li>
            Droit à la limitation (article 18 du RGPD) et à l’opposition du
            traitement des données (article 21 du RGPD) ;{" "}
          </li>
          <li>
            Droit de ne pas faire l’objet d’une décision fondée exclusivement
            sur un procédé automatisé ;{" "}
          </li>
          <li>Droit de déterminer le sort des données après la mort ; </li>
          <li>
            Droit de saisir l’autorité de contrôle compétente (article 77 du
            RGPD).{" "}
          </li>
        </p>
        <p className="justif">
          Pour exercer vos droits, veuillez adresser votre courrier à Aniwebat,
          15 Chemin de Champagné,72470-Saint-Mars-la-Brière, ou par mail à{" "}
          <a href="mailto:lucasledoux@aniwebat.fr">lucasledoux@aniwebat.fr</a>{" "}
          Afin que le responsable du traitement des données puisse faire droit à
          sa demande, l’utilisateur peut être tenu de lui communiquer certaines
          informations telles que : ses noms et prénoms, son adresse e-mail
          ainsi que son numéro de compte, d’espace personnel ou d’abonné.
          Consultez le site cnil.fr pour plus d’informations sur vos droits.{" "}
        </p>
        <h3 className="justif">
          ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
          CONFIDENTIALITÉ{" "}
        </h3>
        <p className="justif">
          L’éditeur du site Aniwebat se réserve le droit de pouvoir modifier la
          présente Politique à tout moment afin d’assurer aux utilisateurs du
          site sa conformité avec le droit en vigueur. Les éventuelles
          modifications ne sauraient avoir d’incidence sur les achats
          antérieurement effectués sur le site, lesquels restent soumis à la
          Politique en vigueur au moment de l’achat et telle qu’acceptée par
          l’utilisateur lors de la validation de l’achat. L’utilisateur est
          invité à prendre connaissance de cette Politique à chaque fois qu’il
          utilise nos services, sans qu’il soit nécessaire de l’en prévenir
          formellement. 
          La présente politique, éditée le 20/01/2023, a été mise à jour le 20/01/2023.
        </p>
      </Container>
    </MainLayout>
  );
};
export default Politiques;
