import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Header = () => {
  return (
    <header>
      <Container fluid>
        <Row>
          <Col md={6}>
            <br></br>
            <h1 className="color-header">Aniwebat,</h1>
            <p className="text-header">
              {" "}
              Découvrez Aniwebat : Votre solution digitale !
            </p>
            <p className="bienvenue">
              Vous cherchez à marquer le web de votre empreinte ? Notre
              expertise en création de sites web sur-mesure est la clé pour une
              présence en ligne éfficace. Vous souhaitez dynamiser votre marque
              sur les réseaux sociaux ? Notre service d'animation transformera
              vos plateformes en véritables hubs d'engagement. De plus, grâce à
              notre service de webmastering, assurez-vous d'un site toujours à
              jour, sécurisé et optimisé pour offrir la meilleure expérience à
              vos visiteurs.<br></br>Avec{" "}
              <span className="text-theme">Aniwebat</span>, donnez à votre
              entreprise le rayonnement digital qu'elle mérite.
            </p>
            <br></br>
            <div className="d-flex justify-content-sm-around">
              <a href="/Contact" className="btn-nav text-center">
                Prendre RDV
              </a>
              {""}
              <a href="/Creation" className="btn-nav text-center">
                Voir nos Offres
              </a>
            </div>
          </Col>
          <Col md={6}>
            <img
              className="img-header"
              alt="animation aniwebat.fr"
              src="../images/animi_webmaster.png"
            ></img>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
export default Header;
