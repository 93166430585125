import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Header = () => {
  return (
    <header>
      <Container fluid>
        <Row>
          <Col md={6}>
            <br></br>
            <h1 className="color-header">Aniwebat,</h1>
            <p className="text-header">
              {" "}
              Construisons ensemble votre présence en ligne !
            </p>
            <p className="bienvenue">
              Dans le monde numérique d'aujourd'hui, un site web est bien plus
              qu'une simple présence en ligne ; c'est le reflet de votre marque,
              le premier point de contact pour de nombreux clients et un outil
              essentiel pour se démarquer dans un marché saturé. Chez{" "}
              <span className="text-theme">Aniwebat</span>, nous comprenons
              l'importance d'un design web de qualité et d'une stratégie
              digitale efficace. Chaque réalisation est le fruit de notre
              passion et de notre expertise, transformant les visions de nos
              clients en réalités digitales impressionnantes. Découvrez
              ci-dessous quelques-unes de nos créations les plus marquantes,
              témoignant de notre engagement à créer des sites web qui attirent,
              engagent et convertissent.
              <span className="text-theme">Aniwebat</span>, bâtissez l'avenir
              numérique de votre entreprise !
            </p>

            <div className="d-flex justify-content-sm-around">
              <a href="/Contact" className="btn-nav text-center">
                Prendre RDV
              </a>
              {""}
              <a href="/Creation" className="btn-nav text-center">
                Voir nos Offres
              </a>
            </div>
          </Col>
          <Col md={6}>
            <img
              className="img-header"
              alt="animation aniwebat.fr"
              src="../images/animation-creationsiteweb.png"
            ></img>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
export default Header;
