/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
  POST_REA,
  GET_REA,
  GETID_REA,
  DELETE_REA,
  GET_REANEWS,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// getAll Article
export const getReas = (data) => {
  return (dispatch) => {
    return api
      .get("/Realisations")
      .then((res) => {
        dispatch({ type: GET_REA, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
// getNews 2 last
export const getReaNews = (data) => {
  return (dispatch) => {
    return api
      .get("/Actu")
      .then((res) => {
        dispatch({ type: GET_REANEWS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// getID Article
export const getReaID = (id) => {
  return (dispatch) => {
    return api
      .get(`/Realisations/${id}`)
      .then((res) => {
        dispatch({ type: GETID_REA, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// Create Article
export const createRea = (data) => {
  return (dispatch) => {
    return api
      .post("/Admin/Gestion", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: POST_REA, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// Delete Article
export const deleteRea = (id) => {
  return (dispatch) => {
    return api
      .delete(`/Admin/Gestion/${id}`)
      .then((res) => {
        dispatch({ type: DELETE_REA, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};