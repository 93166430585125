import React from "react";
import MainLayout from "../layouts/MainLayout";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { urlImg } from "../utils/url";
import { useLocation, useNavigate } from "react-router-dom";
import Produits from "../components/Produits";
import { Seo } from "../components/core/Seo";
const ReaID = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const toRea = async (id) => {
    navigate("/Realisations");
  };

  return (
    <>
      <MainLayout>
      <Seo
          title={`💻📲Aniwebat 🔵 ${state.item.societe}`}
          description="Bienvenue sur Aniwebat.fr. Chez Aniwebat, nous comprenons l'importance d'un design web de qualité et d'une stratégie digitale efficace. Chaque réalisation est le fruit de notre passion et de notre expertise, transformant les visions de nos clients en réalités digitales impressionnantes. Découvrez ici quelques-unes de nos créations les plus marquantes, témoignant de notre engagement à créer des sites web qui attirent, engagent et convertissent. Aniwebat, bâtissez l'avenir numérique de votre entreprise !"
          type="website"
          canonical="https://aniwebat.fr/"
        />
        <Container>
          <br></br>
          <h2 className="fancy2 mt-4 mb-5 text-center">
            🎗️ {state && state.item.societe}
          </h2>
          <Row>
            <Col className="text-center" md={6}>
              <div className="md justify-content-center">
                <img
                  className="img-fluid img-fig"
                  alt={`${state?.item?.societe} site`}
                  src={state?.item?.image ? `${urlImg}${state.item.image}` : ""}
                ></img>
              </div>
            </Col>
            {""}
            <Col md={6}>
              <h3 className="titre-theme text-center">Présentation</h3>
              <p className="justif">{state && state.item.description}</p>

              <div className="text-center">
                ➡️{" "}
                <a href={`${state.item.lien}`} target="_blank" rel="noreferrer">
                  Voir le site
                </a>
              </div>
            </Col>
          </Row>
          <br></br>
          <Col md={12}>
            <h4 className="titre-theme mt-3">Détails techniques</h4>
            <p className="justif">{state && state.item.technologie}</p>
          </Col>

          <div className="text-center mt-3">
            <button className="mb-3 btn-nav" onClick={() => toRea()}>
              👉 Retour aux réalisations
            </button>
          </div>

          <br></br>
          <h3 className="text-center fancy3 mb-2">Nos services</h3>
          <Produits />
        </Container>
      </MainLayout>
    </>
  );
};
export default ReaID;
