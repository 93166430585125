import React from "react";
import Compte from "../../components/Admin/Compte";
import Connexion from "../../components/Admin/Connexion";
import MainLayout from "../../layouts/MainLayout";
import { Seo } from "../../components/core/Seo";

const Register = () => {
  return (
    <div>
      <MainLayout>
      <Seo
          title="💻📲Aniwebat 🔵 S'enregistrer"
          description="Bienvenue sur le site web de Aniwebat. Nous sommes spécialisés dan la création de site web , la gestion de vos sites web et  l'animation de vos réseaux sociaux, près du Mans en Sarthe. Notre mission ? Donner vie à vos projets digitaux tout en garantissant une présence en ligne solide et pérenne.
          Avec Aniwebat, bâtissez l'avenir numérique de votre entreprise ! Vous pouvez nous contacter en remplissant notre formulaire de contact."
          type="website"
        />
        <Connexion />
        <Compte />
      </MainLayout>
    </div>
  );
};
export default Register;
