// Cards Produits
import React from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
const Offres = () => {
  return (
    <div>
      <Container>
        <h3 className="text-center text-theme"> Voir nos Offres</h3>
        <Row>
          <Col md={4}>
            <Card border="primary" className="text-center scale mb-4 mt-4">
              <Card.Header className="text-white">
                Le site "One Page"
              </Card.Header>
              <Card.Body>
                <Card.Title className="text-theme">700 € HT</Card.Title>
                <Card.Text>
                  Toutes les informations dont vous avez besoin regroupées sur
                  une seule page.<br></br> Nous reservons votre nom de domaine
                  et l'hébergeons.
                  <br></br>
                  <br></br>
                  {""}
                  <span className="text-theme">
                    Interressé(e) par cette offre ?{" "}
                  </span>
                  <br></br>
                  <br></br>
                </Card.Text>
                <a href="/Contact" className="btn-offre text-center">
                  Contactez-nous
                </a>
                <br></br>
                <br></br>
              </Card.Body>
              <Card.Footer className="card-footer text-white">
                Profitez-en
              </Card.Footer>
            </Card>
          </Col>
          <Col md={4}>
            <Card border="primary" className="text-center scale mb-4 mt-4">
              <Card.Header className="text-white">
                Le site "Vitrine"
              </Card.Header>
              <Card.Body>
                <Card.Title className="text-theme"> Sur Devis</Card.Title>
                <Card.Text>
                  Jusqu'à 5 pages pour présenter votre entreprise et vos
                  différents services.<br></br> Nous reservons votre nom de
                  domaine et l'hébergeons.
                  <br></br>
                  <br></br>
                  {""}
                  <span className="text-theme">
                    Interressé(e) par cette offre ?{" "}
                  </span>
                  <br></br>
                  <br></br>
                </Card.Text>
                <a href="/Contact" className="btn-offre text-center">
                  Contactez-nous
                </a>
                <br></br>
                <br></br>
              </Card.Body>
              <Card.Footer className="card-footer text-white">
                Profitez-en
              </Card.Footer>
            </Card>
          </Col>
          <Col md={4}>
            <Card border="primary" className="text-center scale mb-4 mt-4">
              <Card.Header className="text-white">Le site "Ultime"</Card.Header>
              <Card.Body>
                <Card.Title className="text-theme">Sur Devis</Card.Title>
                <Card.Text>
                  Plusieurs pages pour réprésenter votre entreprises et vos
                  services avec une administration complète. Nous élaborons avec
                  vous un cahier des charges en fonction de vos besoins.{" "}
                  <br></br> Nous reservons votre nom de domaine et l'hébergeons.
                  <br></br>
                  <br></br>
                  {""}
                  <span className="text-theme">
                    Interressé(e) par cette offre ?{" "}
                  </span>
                </Card.Text>

                <br></br>
                <a href="/Contact" className="btn-offre text-center">
                  Contactez-nous
                </a>
                <br></br>
                <br></br>
              </Card.Body>
              <Card.Footer className="card-footer text-white">
                Profitez-en
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <p className=" justif text-center">
          Nos sites Internet sont dîts "responsive" (adaptés pour tout les types
          d'écran : smartphones,tablettes,ordinateurs) optimisés pour le
          référencement, sécurisés (SSL) et comprennent un formulaire de
          contact.
        </p>
      </Container>
    </div>
  );
};
export default Offres;
