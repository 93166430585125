//Messages
export const POST_MESSAGE = "POST_MESSAGE";
export const GET_MESSAGE = "GET_MESSAGE";
export const GETID_MESSAGE = "GETID_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

//Realisations
export const POST_REA = "POST_REA";
export const GET_REA = "GET_REA";
export const GET_REANEWS = "GET_REANEWS";
export const GETID_REA = "GETID_REA";
export const DELETE_REA = "DELETE_REA";

//Articles
export const POST_ART = "POST_ART";
export const GET_ART = "GET_ART";
export const GET_ARTNEWS = "GET_ARTNEWS";
export const GETID_ART = "GETID_ART";
export const DELETE_ART = "DELETE_ART";

//Gestion Utilisateurs
export const POST_USER = "POST_USER";
export const GET_USER = "GET_USER";
export const GETID_USER = "GETID_USER";
export const LOGIN_USER = "LOGIN_USER";
export const CHECK_AUTH = "CHECK_AUTH";
