import React from "react";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/fr";

const Articles = (props) => {
  const { item } = props;
  const navigate = useNavigate();
  const toArticleID = async (titre) => {
  
    navigate("/Blog/" + item.titre, { state: { titre, item } });
  };
  return (
    <>
      <Col md={6}>
        <Card border="primary" className="scale marg8">
          <Card.Header className="text-white"></Card.Header>
          <Card.Body>
            <h3 className="text-theme">{item.titre}</h3>
            <Card.Text>{item.description}...</Card.Text>

            <button className="btn-nav" onClick={() => toArticleID()}>
              Voir l'article
            </button>
          </Card.Body>
          <Card.Footer className="card-footer text-white">
            Posté le {""}
            <Moment locale="fr" tz="Europe/Paris" format="DD MMMM YYYY à HH:mm">
              {item.date}
            </Moment>{" "}
            <br></br>
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};
export default Articles;
