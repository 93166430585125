import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import PhoneIcon from "@mui/icons-material/Phone";
import jwt_decode from "jwt-decode";
const Navigbar = () => {
  const CheckLoggedIn = () => {
    const userToken = localStorage.getItem("user_aniwe_token");
    if (!userToken)
      return (
        <>
          <Nav className="mx-auto">
            <Nav.Link className="mx-auto" href="/">
              Accueil
            </Nav.Link>
            <NavDropdown className="mx-auto" title="Services" id="nav-dropdown">
              <NavDropdown.Item href="/Animation" eventKey="4.1">
                📲 Animation Réseaux
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Creation" eventKey="4.2">
                🌐 Création de sites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Webmaster" eventKey="4.3">
                📝 Webmastering
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="mx-auto" href="/realisations">
              Réalisations
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Blog">
              Le Blog
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Nous">
              Qui-Sommes-Nous ?
            </Nav.Link>
          </Nav>
          <Nav className="mx-auto">
            <a href="/Contact" className="btn-nav mx-auto text-center">
              Nous-Contacter
            </a>
          </Nav>
          <br></br>
          <Nav className="mx-auto">
            <a
              href="tel:02 43 23 49 59"
              className="btn-nav mx-auto text-center"
            >
              <PhoneIcon sx={{ fontSize: 20 }} />
              02 43 23 49 59
            </a>
          </Nav>
        </>
      );
    else if (userToken === "visitor")
      return (
        <>
          <Nav className="mx-auto">
            <Nav.Link className="mx-auto" href="/">
              Accueil
            </Nav.Link>
            <NavDropdown className="mx-auto" title="Services" id="nav-dropdown">
              <NavDropdown.Item href="/Animation" eventKey="4.1">
                📲 Animation de vos Réseaux
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Creation" eventKey="4.2">
                🌐 Création de site web
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Webmaster" eventKey="4.3">
                📝 Webmastering
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="mx-auto" href="/realisations">
              Réalisations
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Blog">
              Le Blog
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Nous">
              Qui-Sommes-Nous ?
            </Nav.Link>
          </Nav>
          <Nav className="mx-auto">
            <a href="/Contact" className="btn-nav mx-auto text-center">
              Nous-Contacter
            </a>
          </Nav>
          <br></br>
          <Nav className="mx-auto">
            <a
              href="tel:02 43 23 49 59"
              className="btn-nav mx-auto text-center"
            >
              <PhoneIcon sx={{ fontSize: 20 }} />
              02 43 23 49 59
            </a>
          </Nav>
        </>
      );
    else if (jwt_decode(userToken).isAdmin === 1)
      return (
        <>
          <Nav className="mx-auto">
            <Nav.Link className="mx-auto" href="/">
              Accueil
            </Nav.Link>
            <NavDropdown className="mx-auto" title="Services" id="nav-dropdown">
              <NavDropdown.Item href="/Animation" eventKey="4.1">
                📲 Animation de vos Réseaux
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Creation" eventKey="4.2">
                🌐 Création de site web
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Webmaster" eventKey="4.3">
                📝 Webmastering
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="mx-auto" href="/realisations">
              Réalisations
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Blog">
              Le Blog
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Nous">
              Qui-Sommes-Nous ?
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Admin/Gestion">
              ADMIN
            </Nav.Link>
          </Nav>
          <Nav className="mx-auto">
            <a href="/Contact" className="btn-nav mx-auto text-center">
              Nous-Contacter
            </a>
          </Nav>
          <br></br>
        </>
      );
    else
      return (
        <>
          <Nav className="mx-auto">
            <Nav.Link className="mx-auto" href="/">
              Accueil
            </Nav.Link>
            <NavDropdown className="mx-auto" title="Services" id="nav-dropdown">
              <NavDropdown.Item href="/Animation" eventKey="4.1">
                📲 Animation de vos Réseaux
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Creation" eventKey="4.2">
                🌐 Création de site web
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Webmaster" eventKey="4.3">
                📝 Webmastering
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="mx-auto" href="/realisations">
              Réalisations
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Blog">
              Le Blog
            </Nav.Link>
            <Nav.Link className="mx-auto" href="/Nous">
              Qui-Sommes-Nous ?
            </Nav.Link>
          </Nav>
          <Nav className="mx-auto">
            <a href="/Contact" className="btn-nav mx-auto text-center">
              Nous-Contacter
            </a>
          </Nav>
          <br></br>
          <Nav className="mx-auto">
            <a
              href="tel:02 43 23 49 59"
              className="btn-nav mx-auto text-center"
            >
              <PhoneIcon sx={{ fontSize: 20 }} />
              02 43 23 49 59
            </a>
          </Nav>
        </>
      );
  };
  return (
    <div>
      <Navbar className="font-nav navig-theme" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/" className="mx-auto">
            {" "}
            <figure className=" swing2">
              <img
                src="./../logo.png"
                alt="logo aniwebat"
                className="img-fluid"
              ></img>
            </figure>
            {/*    <img
              src="/logo.png"
              className="d-inline-block align-top"
              alt="aniwebat logo"
            />*/}
          </Navbar.Brand>{" "}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <CheckLoggedIn />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
export default Navigbar;
