import React from "react";
import MainLayout from "../layouts/MainLayout";
import Container from "react-bootstrap/Container";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/fr";
import { useLocation, useNavigate } from "react-router-dom";
import Produits from "../components/Produits";
import Header from "../components/core/HeaderBlog";
import { Seo } from "../components/core/Seo";
const BlogID = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const toBlog = async (id) => {
    navigate("/Blog");
  };

  return (
    <>
      <MainLayout>
      <Seo
          title={` ${state.item.titre}`}
          description="Bienvenue sur Aniwebat.fr. Que vous soyez un entrepreneur en manque de temps, un dirigeant / chef d'entreprise curieux, ou encore un passionné cherchant à rester à la pointe des dernières tendances, vous êtes au bon endroit. Plongez avec nous dans ce voyage à travers les innovations qui façonnent le paysage numérique d'aujourd'hui.Avec Aniwebat au Mans, donnez à votre entreprise le rayonnement digital qu'elle mérite"
          type="website"
          canonical="https://aniwebat.fr/"
        />
        <Header />
        <Container>
          <br></br>
          <section className="page-article">
            <h2 className="titre-theme mt-4 mb-4">
              {state && state.item.titre}
            </h2>
            <p className="justif article-p">{state && state.item.contenu}</p>
            <p className="justif date">
              Posté le {""}
              <Moment
                locale="fr"
                tz="Europe/Paris"
                format="DD MMMM YYYY à HH:mm"
              >
                {state && state.item.date}
              </Moment>{" "}
              <br></br>
            </p>
            <div className="text-center mt-3">
              <button className="mb-3 btn-nav" onClick={() => toBlog()}>
                Retour au Blog
              </button>
            </div>
          </section>
          <br></br>
          <h3 className="text-center fancy3 mb-2">Nos services</h3>
          <Produits />
        </Container>
      </MainLayout>
    </>
  );
};
export default BlogID;
