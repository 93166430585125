import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Header = () => {
  return (
    <header>
      <Container fluid>
        <Row>
          <Col md={6}>
            <br></br>
            <h1 className="color-header">Aniwebat,</h1>
            <p className="text-header">
              {" "}
              Construisons ensemble votre présence en ligne !
            </p>
            <br></br>
            <p className="bienvenue">
              Créée en 2023 par deux passionnés issus du monde du bâtiment,
              <span className="text-theme"> Aniwebat</span> fusionne l'expertise
              technique du bâtiment avec le dynamisme du numérique. Nous sommes
              spécialisés dans la réalisation de{" "}
              <span className="text-theme">sites web sur-mesure</span>,{" "}
              <span className="text-theme">
                l'animation de vos réseaux sociaux
              </span>{" "}
              et le <span className="text-theme">webmastering</span>. Nous
              sommes principalement localisés en{" "}
              <span className="text-theme">Sarthe</span> à 20 Km du{" "}
              <span className="text-theme">Mans</span>. <br></br>Notre mission ?
              Donner vie à vos projets digitaux tout en garantissant une
              présence en ligne solide et pérenne.<br></br> Avec{" "}
              <span className="text-theme">Aniwebat</span>, bâtissez l'avenir
              numérique de votre entreprise !
            </p>
            <div className="d-flex justify-content-sm-around">
              <a href="/Creation" className="btn-nav text-center">
                Voir nos Offres
              </a>
              {""}
              <a href="/Blog" className="btn-nav text-center">
                Découvrez notre Blog
              </a>
            </div>
          </Col>
          <Col md={6}>
            <img
              className="img-header"
              alt="animation aniwebat.fr"
              src="./images/anim_contact.png"
            ></img>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
export default Header;
