import React from "react";
import Container from "react-bootstrap/Container";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
const Bienvenue = () => {
  return (
    <Container>
      <p className="text-center bienvenue">
        <span className="text-theme">
          Vous pouvez retrouver nos services juste en dessous.
        </span>
      </p>

      <div className="text-center mb-4">
        <KeyboardDoubleArrowDownIcon
          className="bienvenue-style"
          sx={{ fontSize: 70 }}
        />
      </div>
    </Container>
  );
};
export default Bienvenue;
