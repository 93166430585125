import React from "react";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import { deleteArt } from "../../store/actions/ArtAction";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/fr";

const AdminArt = (props) => {
  const { item } = props;
  const navigate = useNavigate();
  const toArticleID = async (titre) => {
    navigate("/Blog/" + item.titre, { state: { titre, item } });
  };
  const dispatch = useDispatch();
  const handleDelete = async (id) => {
    dispatch(deleteArt(id));
    window.location.reload();
  };
  return (
    <>
      <Col md={6}>
        <Card border="primary" className="scale marg8">
          <Card.Header className="text-white"></Card.Header>
          <Card.Body>
            <Card.Title className="text-theme">{item.titre}</Card.Title>
            <Card.Text>{item.description}...</Card.Text>

            <button className="btn-nav" onClick={() => toArticleID()}>
              Voir l'article
            </button>
            <div className="text-center">
              <Button
                variant="outline-danger"
                type="submit"
                onClick={() => handleDelete(item.id)}
              >
                Supprimer
              </Button>{" "}
            </div>
          </Card.Body>
          <Card.Footer className="card-footer text-white">
            Posté le {""}
            <Moment locale="fr" tz="Europe/Paris" format="DD MMMM YYYY à HH:mm">
              {item.date}
            </Moment>{" "}
            <br></br>
          </Card.Footer>
        </Card>
      </Col>
    </>
  );
};
export default AdminArt;
