import React from "react";
import Col from "react-bootstrap/Col";
import { urlImg } from "../../utils/url";
import { useNavigate } from "react-router-dom";
const Exemples = (props) => {
  const { item } = props;
  const navigate = useNavigate();
  const toReaID = async (societe) => {
    navigate("/realisations/" + item.societe, { state: { societe, item } });
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Col md={4}>
        <figure className="text-center">
          <a
            href="#top"
            onClick={(e) => {
              e.preventDefault(); // Empêche la navigation par défaut de l'élément <a>
              toReaID();
            }}
            rel="noreferre"
          >
            <img
              className="img-fluid"
              alt={`${item.societe + "site aniwebat"}`}
              src={`${urlImg + item.image}`}
            ></img>
          </a>
          <br></br>
          <figcaption>{item.societe}</figcaption>
        </figure>
      </Col>
    </>
  );
};
export default Exemples;
