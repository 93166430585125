/*
 * Import Actions { ... }
 * ********************** */
import * as Actions from "../actions/ActionsTypes";

/*
 * Selector
 * ******** */
const initialState = {
  flash: "",
  listArts: [],
  arts: {},
};

/*
 * Reducers
 * ******** */
export function ArtReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case Actions.GET_ART:
      return {
        ...state,
        flash: action.payload.flash,
        listArts: action.payload.dbArt,
      };
    case Actions.GET_ARTNEWS:
      return {
        ...state,
        flash: action.payload.flash,
        listArts: action.payload.dbArt,
      };
    case Actions.GETID_ART:
      return {
        ...state,
        flash: action.payload.flash,
        arts: action.payload.arts,
      };
    case Actions.POST_ART:
      return {
        ...state,
        flash: action.payload.flash,
        listArts: action.payload.dbArt,
      };
    case Actions.DELETE_ART:
      return {
        ...state,
        flash: action.payload.flash,
        listArts: action.payload.dbArt,
      };
  }
}

/*
 * Getters
 * ******* */
