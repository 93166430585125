import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useDispatch } from "react-redux";
import { createArt, getArt } from "../../store/actions/ArtAction";

const AdminGestionArt = () => {
  const [titre, setTit] = useState("");
  const [description, setDesc] = useState("");
  const [contenu, setCont] = useState("");
  const dispatch = useDispatch();

  const handleForm = async (e) => {
    e.preventDefault();
    if (titre && description && contenu) {
      await dispatch(createArt({ titre, description, contenu }));
      setTit("");
      setDesc("");
      setCont("");
      await dispatch(getArt());
      window.location.reload();
      window.alert("Ajout Article !");
    }
  };
  return (
    <div>
      <Container>
        <Form onSubmit={(e) => handleForm(e)}>
          <Row>
            <Col md={12}>
              <FloatingLabel controlId="titre" label="Titre">
                <Form.Control
                  type="text"
                  placeholder="Titre"
                  className="mb-3"
                  maxLength={100}
                  value={titre}
                  onChange={(e) => setTit(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col md={12}>
              <FloatingLabel controlId="description" label="Description">
                <Form.Control
                  as="textarea"
                  rows={4}
                  className="mb-3"
                  maxLength={255}
                  value={description}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Col sm={12}>
            <FloatingLabel controlId="Contenu" label="Contenu">
              <Form.Control
                as="textarea"
                rows={4}
                className="mb-3"
                value={contenu}
                onChange={(e) => setCont(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <div className="text-center">
            <Button className="mb-5" type="submit">
              Envoyer
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};
export default AdminGestionArt;
