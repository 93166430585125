/*
 * Import - Module
 * *************** */
import { api } from "../../config/axios";

/*
 * Import types { ... }
 * ******************** */
import {
  POST_ART,
  GET_ART,
  GETID_ART,
  DELETE_ART,
  GET_ARTNEWS,
} from "./ActionsTypes";

/*
 * Actions
 * ******* */

// getAll Article
export const getArt = (data) => {
  return (dispatch) => {
    return api
      .get("/blog")
      .then((res) => {
        dispatch({ type: GET_ART, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
// getARTnews 3  last
export const getArtNews = (data) => {
  return (dispatch) => {
    return api
      .get("/blog/news")
      .then((res) => {
        dispatch({ type: GET_ARTNEWS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// getID Article
export const getArtID = (id) => {
  return (dispatch) => {
    return api
      .get(`/blog/${id}`)
      .then((res) => {
        dispatch({ type: GETID_ART, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// Create Article
export const createArt = (data) => {
  return (dispatch) => {
    return api
      .post("/Admin/blog", data)
      .then((res) => {
        dispatch({ type: POST_ART, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// Delete Article
export const deleteArt = (id) => {
  return (dispatch) => {
    return api
      .delete(`/Admin/blog/${id}`)
      .then((res) => {
        dispatch({ type: DELETE_ART, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
