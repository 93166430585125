import React from "react";
import Col from "react-bootstrap/Col";
import { urlImg } from "../../utils/url";
import { useDispatch } from "react-redux";
import { deleteRea } from "../../store/actions/ReaActions";
import Button from "react-bootstrap/Button";

const AdminRea = (props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const handleDelete = async (id) => {
    dispatch(deleteRea(id));
    window.location.reload();
  };
  return (
    <>
      <Col md={6}>
        <section className="page-article">
          <h3 className="titre-theme text-center">{item.societe}</h3>
          <img
            className="img-fluid"
            alt={`${item.societe + "site"}`}
            src={`${urlImg + item.image}`}
          ></img>
          <a href={`${item.lien}`} target="_blank" rel="noreferrer">
            lien du site
          </a>

          <p>
            Présentation : <br></br>
            {item.description}
            <br></br>
            <br></br>
            Détails Techniques : <br></br>
            {item.technologie}
          </p>
          <div className="text-center">
            <Button
              variant="outline-danger"
              type="submit"
              onClick={() => handleDelete(item.id)}
            >
              Supprimer
            </Button>{" "}
          </div>
        </section>
      </Col>
      <br></br>
      <br></br>
    </>
  );
};
export default AdminRea;
